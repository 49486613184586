
import { Box, Text } from 'grommet';
import { FormDown, FormUp } from 'grommet-icons';
import React, { useEffect } from 'react';
import Separator from '../separator';

const initExpandable = item => {
    let value = false;
    if(!item.children) {
        value = false;
    }
    else if (item.children.length > 0) {
        value = true;
    }
    console.log(item, value);
    return value;
}

const PrestationItem = ({ item, isEnding, size, willExpand }) => {
    const [expanded, setExpanded] = React.useState(willExpand && item.children && item.children.length > 0);

    useEffect(() => {
        let _ex = willExpand && item.children && item.children.length > 0;
        setExpanded(_ex);
    }, [item]);
    const showPopup = () => {
        console.log('show popup')
    }

    const expand = () => {
        if(!item.children) return;
        setExpanded(!expanded);
    }
    return (
        <Box style={{ width: '100%' }}
            gap="small"
        >
            <Box gap="xsmall">
                <Box gap="xsmall"
                    justify="between"
                    onClick={() => expand(!expanded)}
                    direction="row"
                    style={{ boxShadow: 'none' }}
                >
                    <Text style={{ color: '#3b3b3b' }} size={size || 'medium'}>{item.title}</Text>
                    <Box direction="row" gap="small" size={size || 'medium'}>
                        {!expanded && <Text size={size || 'medium'} color='#3b3b3b' style={{ fontWeight: 'bold' }}>{item.price}</Text>}
                        { item.children ? (expanded ? <FormUp /> : <FormDown />) : null}
                    </Box>
                </Box>
                <Box gap="small"
                    direction="row"
                >
                    {!expanded && <Text size={size || 'medium'} style={{ cursor: 'pointer', color: '#707070' }} onClick={() => expand(!expanded)}>{item.time}</Text>}
                    <Text size={size || 'medium'} style={{ cursor: 'pointer', color: '#001e62' }} onClick={() => showPopup()}>Ma prestation en détail...</Text>
                </Box>
            </Box>


            {expanded && item.children &&
                <Box style={{ width: '100%', paddingLeft: 15 }}>
                    {
                        item.children.map((subItem, index) => (
                            <SubItem
                                subItem={subItem} key={index}
                                size={size}
                                isEnding={index === item.children.length - 1}
                            />
                        ))
                    }
                </Box>
            }
            {isEnding ? <Box style={{height: 15}}/> : <Separator />}
        </Box>
    )
}


const SubItem = ({ subItem, isEnding, size }) => (
    <Box style={{ width: '100%' }}>
        <Box gap="xsmall" >
            <Box style={{ width: '100%' }}
                gap="small"
                justify="between"
                direction="row"
            >
                <Text size={size || 'medium'} style={{ color: '#707070' }}>{subItem.title}</Text>
                <Text size={size || 'medium'} color='#3b3b3b' style={{ fontWeight: 'bold' }}>{subItem.price}</Text>
            </Box>
            <Text size={size || 'medium'} style={{ color: '#707070' }}>{subItem.time}</Text>
        </Box>
        {!isEnding && <Separator /> }
    </Box>
)
export default PrestationItem;