import { Box, Heading, ResponsiveContext } from 'grommet';
import React from 'react';
import Layout from '../components/elements/layout';
import data from '../components/elements/prestation/data';
import salon from '../config/data.json';
import { getSalon } from '../services/service';
import MainMenu from '../components/menu';
import Header from '../components/header'
import PrestationItem from '../components/elements/prestation/item';
import MainSeparator from '../components/elements/separator/main_separator';

const seo = {
    title: 'BE PERFECT MANUCURE | Nos Prestations',
    description: 'Nous proposons ensemble des prestations de beauté des mains et des pieds, et aussi des nail arts, des faux ongles...'
}

class Prestations extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.salon || {
            salon
        }
    }

    componentDidMount = () => {
        getSalon()
            .then(res => {
                this.setState({ salon: res.data })
            })
    }



    render() {
        return (
            <Layout style={{ justifyContent: "center", alignContent: "center" }}
                title={seo.title}
                description={seo.description}
            >
                <Box width="xlarge" justify="center" align="center" alignSelf="center">
                    <ResponsiveContext.Consumer>
                        {size =>
                            <Box width="xlarge" pad="medium">
                                <Header data={this.state.salon.data} size={size} />
                                <MainMenu />
                                <Heading level="3">Manucure</Heading>
                                <Box style={{ width: '100%' }}>
                                    {
                                        data.prestations[0].data.map((item, index) => (
                                            <PrestationItem
                                                key={index}
                                                item={item}
                                                willExpand={true}
                                                isEnding={index === data.prestations[0].data.length - 1}
                                            />
                                        ))
                                    }
                                </Box>

                                <MainSeparator />
                                
                                <Heading level="3">Beauté des pieds</Heading>
                                <Box style={{ width: '100%' }}>
                                    {
                                        data.prestations[1].data.map((item, index) => (
                                            <PrestationItem
                                                key={index}
                                                item={item}
                                                willExpand={true}
                                                isEnding={index === data.prestations[1].data.length - 1}
                                            />
                                        ))
                                    }
                                </Box>
                                

                                <MainSeparator />
                                
                                <Heading level="3">Manucure et Beauté des pieds</Heading>
                                <Box style={{ width: '100%' }}>
                                    {
                                        data.prestations[2].data.map((item, index) => (
                                            <PrestationItem
                                                key={index}
                                                item={item}
                                                willExpand={true}
                                                isEnding={index === data.prestations[2].data.length - 1}
                                            />
                                        ))
                                    }
                                </Box>
                                <MainSeparator />
                                
                                <Heading level="3">Faux Ongles</Heading>
                                <Box style={{ width: '100%' }}>
                                    {
                                        data.prestations[3].data.map((item, index) => (
                                            <PrestationItem
                                                key={index}
                                                item={item}
                                                willExpand={true}
                                                isEnding={index === data.prestations[3].data.length - 1}
                                            />
                                        ))
                                    }
                                </Box>
                            </Box>
                        }
                    </ResponsiveContext.Consumer>

                    <Box style={{ height: 200 }} />
                </Box>
            </Layout>
        )
    }

}

export default Prestations;