import React from 'react';
import { Box, Tab, Tabs } from 'grommet';
import { navigate } from 'gatsby-link';

const Menu = () => {


    const initIndex = () => {
        let path = '';
        if (typeof window !== `undefined`) {
            path = window.location.pathname;
        }
        console.log('path: ', path);
        switch (path) {
            case '/':
            case '':
                return 0;
            case '/prestations':
                return 1;
            case '/works':
                return 2;
            case '/avis':
                return 3;
            default:
                break;
        }
        return 0;

    }
    const [activeIndex, setActiveIndex] = React.useState(initIndex());

    const onActive = (nextIndex) => {
        const search = window.location.search;
        switch (nextIndex) {
            case 0:
                navigate('/' + search)
                break;
            case 1:
                navigate('/prestations' + search);
                break;
            case 2:
                navigate('/works' + search);
                break;
            case 3:
                navigate('/avis' + search);
                break;
            default:
                break;
        }
        setActiveIndex(nextIndex);
    }

    return (
        <Box
            direction="row"
            alignContent="between"
            style={{ height: 80, width: "100%" }}
        >
            <Box alignContent="center" justify="center" style={{ width: "100%" }}>
                <Tabs activeIndex={activeIndex} onActive={onActive}>
                    <Tab title="Accueil" onClick={() => navigate('/')}>

                    </Tab>
                    <Tab title="Prestations" onClick={() => navigate('/prestations')}>

                    </Tab>
                    <Tab title="Notre Travail">

                    </Tab>
                    <Tab title="Avis">

                    </Tab>
                </Tabs>
            </Box>
        </Box>
    );
}

export default Menu;